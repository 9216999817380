<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body class="p-1">
                <b-overlay
                        :show="state.loading"
                        rounded="sm"
                >
                    <b-row>
                        <b-col cols="12" md="6">
                            <b-table
                                    ref="refUserListTable"
                                    :items="items"
                                    :small="true"
                                    responsive
                                    :fields="columns"
                                    primary-key="id"
                                    show-empty
                                    empty-text="اطلاعاتی یافت نشد"
                                    class="text-nowrap"
                                    style="min-height : 235px"
                            >
                                <template #cell(relatedCoin)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <b-avatar
                                                    size="32"
                                                    :src="require(`@/assets/images/Coins/${data.item.coin}.png`)"
                                                    :variant="`light-info`"
                                            />
                                        </template>
                                        <b-link
                                                class="font-weight-bold d-block text-nowrap"
                                        >
                                            {{ $coinLabel[data.item.coin] }}
                                        </b-link>
                                        <small class="text-muted">{{ $coinUnit[data.item.coin] }}</small>
                                    </b-media>
                                </template>

                                <template #cell(totalBalance)="data">
                                    <span dir="ltr">
                                        {{data.item.feeSum ? $toLocal(data.item.feeSum,$decimal[data.item.coin]) : 0}}
                                    </span>
                                </template>

                            </b-table>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-table
                                    ref="refUserListTable"
                                    :items="items1"
                                    :small="true"
                                    responsive
                                    :fields="columns"
                                    primary-key="id"
                                    show-empty
                                    empty-text="اطلاعاتی یافت نشد"
                                    class="text-nowrap"
                                    style="min-height : 235px"
                            >
                                <template #cell(relatedCoin)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <b-avatar
                                                    size="32"
                                                    :src="require(`@/assets/images/Coins/${data.item.coin}.png`)"
                                                    :variant="`light-info`"
                                            />
                                        </template>
                                        <b-link
                                                class="font-weight-bold d-block text-nowrap"
                                        >
                                            {{ $coinLabel[data.item.coin] }}
                                        </b-link>
                                        <small class="text-muted">{{ $coinUnit[data.item.coin] }}</small>
                                    </b-media>
                                </template>

                                <template #cell(totalBalance)="data">
                                    <span dir="ltr">
                                        {{data.item.feeSum ? $toLocal(data.item.feeSum,$decimal[data.item.coin]) : 0}}
                                    </span>
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-card>
        </b-col>
        <b-col cols="12">
            <b-row>
                <b-col lg="8">
                    <fee-line-chart :chart="volChart" :sum.sync="sum"/>
                </b-col>
                <b-col lg="4">
                    <b-overlay
                            :show="loading"
                            rounded="sm"
                    >
                        <fee-filter-chart
                                :date-param.sync="dateParam"
                                :sum="sum"
                                :related-coin.sync="relatedCoin"
                                :coins="[...items,...items1]"
                                @change="getChartData"
                        />
                    </b-overlay>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>

    import {
        BCard,
        BTable,
        BMedia,
        BAvatar,
        BOverlay,
        BLink,
        BRow,
        BCol,
    } from 'bootstrap-vue'
    import FeeLineChart from "@/views/Accounting/Fees/feeLineChart";
    import FeeFilterChart from "@/views/Accounting/Fees/feeFilterChart";
    // import NotAllowed from "@/layouts/components/NotAllowed";

    export default {
        name: 'Wallets',
        components: {
            FeeFilterChart,
            FeeLineChart,
            BCard,
            BTable,
            BMedia,
            BAvatar,
            BOverlay,
            BLink,
            BRow,
            BCol,
        },
        data: () => ({
            dir: false,
            loading: false,
            coins: [],
            items: [],
            items1: [],
            columns: [
                {
                    label: 'رمز ارز',
                    key: 'relatedCoin',
                    sortable: true,
                    searchType: 'text'
                },
                {
                    label: 'سود حاصله',
                    key: 'totalBalance',
                    sortable: true,
                    searchType: 'number'
                },
            ],
            volChart: '',
            sum: 0,
            duration: 'MONTHLY',
            number: 0,
            relatedCoin: 'BITCOIN',
            dateParam: {
                from: '2021-01-01T00:00:00',
                to: '2021-01-01T00:00:00'
            },
            type: false
        }),
        methods: {
            async getData() {
                this.state.loading = true

                const res = await this.$axios.get('/trades/all-fees')

                this.items = res.data.content.slice(0, Math.ceil(res.data.content.length / 2))
                this.items1 = res.data.content.slice(Math.ceil(res.data.content.length / 2))
            },

            async getChartData() {
                this.loading = true
                const res = await this.$axios('/trades/fees', {
                    params: {
                        relatedCoin: this.relatedCoin,
                        ...this.dateParam
                    }
                }).catch(() => {
                    this.loading = false
                })
                this.volChart = res.data.content
                this.loading = false
            },
        },
        async created() {
            await this.getData()
            await this.getChartData()
        }
    }
</script>
<style lang="scss">
    [v-cloak] {
        opacity: 0;
    }

    [dir="rtl"] .test-badge {
        font-size: 10px;
        top: -6px;
        left: -5px !important;
        min-width: 16px;
        min-height: 16px;
    }
</style>
